import { ProductDetailsState } from "../utils/types/ui/productDetailsState";
import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import notificationToUser from "../utils/helperFunction/notificationToUser";
import api from "./api";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
import { toast } from "react-toastify";
import i18n from "../i18n";
import dayjs from "dayjs";
import { AddPcsTranslation, PcsTranslateProps } from "../utils/types/services/pcsTranslate";

const STATUS_200 = 200;

export const saveProductDetails = async (
  data: ProductDetailsState[]
): Promise<{
  success: boolean;
  status?: number;
}> => {
  let saveProductDetailsResult = null;
  try {
    saveProductDetailsResult = await api.post(
      API_ROUTE_FULL_PATHS.saveProductDetails,
      { drugsToAdd: data }
    );

    if (saveProductDetailsResult.status === STATUS_200) {
      toast.success(saveProductDetailsResult.data.msg);
      return {
        success: true,
      };
    }

    notificationToUser({ msg: saveProductDetailsResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: saveProductDetailsResult });
  }
};

export const downloadRDPReport = async (data: {
  fromDatea: string;
  toDatea: string;
}): Promise<{
  success: boolean;
  status?: number;
  headers?: any;
}> => {
  let downloadtestReportsResult = null;
  try {
    downloadtestReportsResult = await api.post(
      API_ROUTE_FULL_PATHS.itadminGetReports,
      {
        fromDate: data.fromDatea,
        toDate: data.toDatea,
      },
      {
        responseType: "blob",
      }
    );

    if (downloadtestReportsResult.status === STATUS_200) {
      const blobData = new Blob([downloadtestReportsResult.data]);
      const url = window.URL.createObjectURL(blobData);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${dayjs().format("DDMMMYYYY_HHmmss")}.xlsx`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      const headers = downloadtestReportsResult.headers;
      toast.success(i18n.t("itadminrwdreport.downloadSuccess") as string);
      return {
        success: true,
        headers,
      };
    } else {
      notificationToUser({ msg: downloadtestReportsResult.data.msg });
      return {
        success: false,
      };
    }
  } catch (err) {
    return {
      success: false,
    };
  } finally {
    handleRequestFinally({ result: downloadtestReportsResult });
  }

  
};
export const hcpDeleteProfile = async (
  email: string
): Promise<{
  success: boolean;
  status?: number;
}> => {
  let deleteProfile = null;
  try {
    deleteProfile = await api.post(API_ROUTE_FULL_PATHS.deleteProfile, {
      email,
    });

    if (deleteProfile.status === STATUS_200) {
      toast.success(deleteProfile.data.msg);
      return {
        success: true,
      };
    }

    notificationToUser({ msg: deleteProfile.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: deleteProfile });
  }
};

export const getAllTranslations = async (): Promise<{
  success: boolean;
  data?: PcsTranslateProps[];
}> => {
  try {
    const response = await api.get(API_ROUTE_FULL_PATHS.getAllTranslations);
    const data: PcsTranslateProps[] = response.data;
    return {success: true, data}
  } catch (error) {
    return handleRequestErr(error);
  }
}

export const addTranslation = async (
  english: string,
  mandarin: string
): Promise<{
  success: boolean;
}> => {
  let response = null;
  try {
    response = await api.post(API_ROUTE_FULL_PATHS.addTranslation, { english, mandarin });
    console.log(response);
    if(response.status===STATUS_200){
      toast.success(response.data.msg);
    }
    return {success: true}
  } catch (error) {
    return {success: false}
  } finally {
    handleRequestFinally({ result: response });
  }
}

export const deleteTranslation = async (
  id: number
): Promise<{
  success: boolean;
}> => {
  try {
    const response = await api.post(API_ROUTE_FULL_PATHS.deleteTranslation, {id});
    if(response.status===STATUS_200){
      toast.success(response.data.msg);
    }
    return {success: true}
  } catch (error) {
    return handleRequestErr(error);
  }
}

export const updateTranslation = async (
  id: number,
  english: string,
  mandarin: string
): Promise<{
  success: boolean;
}> => {
  try {
    const response = await api.post(API_ROUTE_FULL_PATHS.updateTranslation, {id,english,mandarin});
    if(response.status===STATUS_200){
      toast.success(response.data.msg);
    }
    return {success: true}
  } catch (error) {
    return {success: false}
  } 
}

export const getTranslation = async (
  id: number
): Promise<{
  success: boolean;
  data?: PcsTranslateProps[];
}> => {
  try {
    const response = await api.post(API_ROUTE_FULL_PATHS.getTranslation, {id});
    const data: PcsTranslateProps[] = response.data;
    return {success: true, data}
  } catch (error) {
    return handleRequestErr(error);
  }
}

export const mobileValidate = async (
  mobile: string,
  email: string
): Promise<any> => {
  try {
    const response = await api.post(API_ROUTE_FULL_PATHS.getHcpByMobile, {mobile,email});
    return {success: response?.data?.success, mobile: response?.data?.mobile}
  } catch (err) {
    console.log(err);
    return;
  } 
};

